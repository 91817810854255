<template>
  <div>
    <b-tooltip
      label="Export the dataset"
    >
      <b-button
        rounded
        icon-left="download"
        size="is-small"
        @click="displayForm=true"
      />
    </b-tooltip>
    <b-modal
      :active.sync="displayForm"
      has-modal-card
    >
      <div class="modal-card">
        <validation-observer v-slot="{ handleSubmit }">
          <header class="modal-card-head">
            <p class="modal-card-title">Export dataset “{{ dataset.user_name }}”</p>
          </header>
          <section class="modal-card-body">
            <validation-provider
              v-slot="{ errors}"
              :rules= {required:true}
              name="name"
            >
              <b-field
                label="Name of the exported file (a unique identifier will be appended to this name)"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  v-model="fileName"
                  expanded
                />
                <p class="control">
                  <b-select
                    v-model="fileExtension"
                    placeholder="File extension"
                  >
                    <option value="csv">.csv</option>
                    <option value="rda">.rda</option>
                  </b-select>
                </p>
              </b-field>
            </validation-provider>
          </section>
          <footer class="modal-card-foot">
            <div class="has-text-right">
              <b-button
                rounded
                label="Export"
                type="is-primary"
                @click="handleSubmit(exportDataset)"
              >
              </b-button>
            </div>
          </footer>
        </validation-observer>
      </div>
    </b-modal>
    <a hidden ref="export" href=""></a>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import apiService from '@/services/apiService'
import url from '@/services/urlEnvironment.js'

export default {
  name: 'DatasetExport',
  props: {
    dataset: {
      type: Object,
      default: null
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  data () {
    return {
      displayForm: false,
      fileName: null,
      fileExtension: 'csv',
      sep: ',',
      na: '',
      eol: 'n',
      dec: '.',
      encoding: '',
      error: ''
    }
  },
  methods: {
    exportDataset: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      apiService.runRFunction({
        'func_name': 'r_export',
        'datasetName': this.dataset.object_name,
        'fileName': this.fileName,
        'fileExtension': this.fileExtension,
        'sep': ',',
        'na': '',
        'eol': '\n',
        'dec': '.',
        'encoding': 'UTF-8'
      })
        .then(data => {
          this.$refs.export.setAttribute('href', url.API_URL + '/get_file?file=' + data.Exported.path)
          this.$refs.export.click()
          this.displayForm = false
          loadingComponent.close()
        })
        .catch(error => {
          this.error = error.data.error
          loadingComponent.close()
        })
    }
  }
}
</script>
